<template>
    <div id="user-box">

        <!-- 查询条件 -->
        <el-form :inline="true" :model="search" class="demo-form-inline" style="text-align:center;">
            <el-form-item label="姓名:">
                <el-input v-model="search.user_name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话:">
                <el-input v-model="search.phone" placeholder="电话"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="ToSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!-- 用户列表 -->
        <el-table :data="userList" :cell-style="{padding:'2px 0'}" height="634" border stripe>
            <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
            <el-table-column prop="openid" label="微信标识" align="center" width="180"></el-table-column>
            <el-table-column prop="user_name" label="姓名" align="center" width="180"></el-table-column>
            <el-table-column prop="phone" label="电话" align="center" width="180"></el-table-column>
            <el-table-column prop="weixin" label="地址" align="center" width="180"></el-table-column>
            <!--<el-table-column prop="qqnumber" label="qq号" align="center" width="180"></el-table-column>
            <el-table-column prop="email" label="邮箱" align="center" width="180"></el-table-column> -->
            <el-table-column prop="create_time" label="创建时间" align="center" width="180"></el-table-column>
            <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button @click="toOrder(scope.row.id)" type="primary">订单</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.CurrPage" :page-sizes="[10,15,20,25]" :page-size="page.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.DataCount" :sizes='page.PageCount'>
        </el-pagination>
    </div>
</template>

<script>
import { getForm } from "../tools/tool"

export default {
    name: "UserView",
    data() {
        return {
            search: {
                CurrPage: 1,
                PageSize: 10,
            },
            page: {
                CurrPage: 1, //初始页
                PageSize: 10, // 每页的数据
                DataCount: 0, // 总条数
                PageCount: 0, // 总页数
            },
            userList: [],
        }
    },

    created() {
        this.onSubmit()
    },
    mounted() { },

    methods: {
        onSubmit() {
            getForm('/getuserlist', this.search)
                .then(data => {
                    this.userList = data.data
                    this.page = data.page
                    console.log('userlist:', this.userList)
                }).catch(err => {
                    alert(err)
                })
        },
        ToSearch() {
            this.search.CurrPage = 1
            this.onSubmit()
        },
        toOrder(userId) {
            this.$router.push({
                path: "/order",
                query: { userId: userId }
            })
        },

        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.search.PageSize = size;
            this.onSubmit()
        },
        handleCurrentChange: function (CurrPage) {
            this.search.CurrPage = CurrPage;
            console.log("CurrPage: ", this.search.CurrPage)
            console.log(this.search.CurrPage) //点击第几页
            this.onSubmit()
        },
    }
}

</script>

<style scoped lang="less">
</style>
